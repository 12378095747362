import React, { useContext } from "react";
import { Col, Row } from "react-bootstrap";
import { TarjetaDatoCoberturaComponent } from "../tarjeta-datos-cobertura";
import { DatoContactoComponent } from "../dato-contacto";
import { DatoPersonalComponent } from "../dato-personal";
import "./datos-cobertura.scss";
import { MiCoberturaComponent } from "../mi-cobertura";
import { UserGlobalContextMemorySpace } from "../../../../contexts/user/userGlobalContext";
import { useToggle } from "../../../../hooks/useToggle";
import { MessageContext } from "../../../../contexts/message/messageContext";
import { Message } from "../../message/message";
import { myAxios, fetchLogs } from "../../../../utils/fetch";
import ReactGA from "react-ga4";

export const DatosCoberturaComponent = () => {
  const { userPSHB, user, setUser } = useContext(UserGlobalContextMemorySpace);
  const [keyCobertura, setKeyCobertura] = React.useState(Math.random());
  const toggle = useToggle();
  const [message, setMessage] = React.useState({ error: false, message: "" });

  const [state, setState] = React.useState({
    editando: false,
    editandoDatoPersonal: false,
    editandoDatoContacto: false,
    editandoMiCobertura: false,
    visibleDatoPersonal: true,
    visibleDatoContacto: false,
    visibleMiCobertura: false,
    guardando: false,
  });

  const onVisibleTarjeta = (tarjeta) => {
    const nuevoValor = !state[tarjeta];

    if (!state.editando) {
      if (tarjeta === "visibleDatoContacto") {
        setState({
          ...state,
          [tarjeta]: nuevoValor,
          visibleMiCobertura: false,
        });
      }

      if (tarjeta === "visibleMiCobertura") {
        setState({
          ...state,
          [tarjeta]: nuevoValor,
          visibleDatoContacto: false,
        });
      }
    }
  };

  const onEditando = (tarjeta) => {
    setState({
      ...state,
      editando: !state[tarjeta],
      [tarjeta]: !state[tarjeta],
    });
  };

  function compararObjetos(objetoAntiguo, objetoNuevo) {
    const diccionario = {
      socialSecurityNumber: "N° afiliado",
      socialSecurity: "Cobertura",
      socialSecurityPlan: "Plan",
      mobile: "Celular",
      phone: "Fijo",
    };
    const atributosCambiados = {};
    for (const clave in objetoNuevo) {
      if (objetoNuevo.hasOwnProperty(clave)) {
        if (objetoAntiguo[clave] !== objetoNuevo[clave]) {
          atributosCambiados[diccionario[clave]] = objetoNuevo[clave];
        }
      }
    }
    const formatoCampoValor = Object.entries(atributosCambiados)
      .map(([campo, valor]) => `${campo}: ${valor}`)
      .join(", ");
    return formatoCampoValor;
  }

  const cancelar = (tarjeta, setTemporalUser) => {
    onEditando(tarjeta);
    if (tarjeta === "editandoMiCobertura") {
      setKeyCobertura(Math.random());
    } else {
      setTemporalUser(JSON.parse(JSON.stringify(user)));
    }
  };
  const confirmar = (tarjeta, temporalUser) => {
    setState({ ...state, guardando: true });
    let datosEditados = "contacto.";
    if (tarjeta === "editandoMiCobertura") {
      datosEditados = "cobertura.";
      if (temporalUser.user.data.Customers.socialSecurityPlan === "") {
        setMessage({
          error: true,
          message: "Debe de seleccionar un plan",
        });

        toggle.onToggle();
        setState({ ...state, guardando: false });
        return;
      }
    }

    let socialSecurityNumber =
      temporalUser.user.data.Customers.socialSecurityNumber.replaceAll(" ", "");
    socialSecurityNumber =
      socialSecurityNumber.slice(0, -2) + " " + socialSecurityNumber.slice(-2);
    temporalUser.user.data.Customers.socialSecurityNumber =
      socialSecurityNumber;
    const Customer = temporalUser.user.data.Customers;

    const payLoad = {
      idCustomer: Customer.idCustomer,
      idSap: Customer.idSap,
      idTemp: Customer.idTemp,
      phone: Customer.phone,
      cellCompany: Customer.cellCompany,
      mobile: Customer.mobile,
      socialSecurity: Customer.socialSecurity,
      socialSecurityNumber: Customer.socialSecurityNumber,
      socialSecurityPlan: Customer.socialSecurityPlan,
    };

    var regex = /^[0-9]+$/;
    let noErrorMobile = true;
    let noErrorPhone = true;

    if (Customer.phone != "") {
      noErrorPhone = regex.test(Customer.phone);
    }
    if (Customer.mobile != "") {
      noErrorMobile = regex.test(Customer.mobile);
    }

    if (noErrorMobile && noErrorPhone) {
      if (Customer.mobile != "" || Customer.phone != "") {
        myAxios({
          url:
            process.env.REACT_APP_GATEWAY_QA +
            "/customers/Api/v1/UpdatePatient",
          method: "PUT",
          data: payLoad,
          headers: {
            "Content-Type": "application/json",
            USERTOKEN: temporalUser.user.data.Token,
            Authorization: `Bearer ${temporalUser.user.data.appToken}`,
          },
        })
          .then((response) => {
            setState({ ...state, guardando: false });
            if (!response.data.isError) {
              setUser(JSON.parse(JSON.stringify(temporalUser)));
              onEditando(tarjeta);
              setMessage({ error: false, message: "Datos enviados." });
              try {
                fetchLogs({
                  idLogType: 1,
                  idLog: 3,
                  source: "pshb",
                  email: user.user.data.Customers.email,
                  dni: user.user.data.Customers.dni ?? "",
                  credencial: user.user.data.Customers.credencial ?? "",
                  description:
                    "El usuario " +
                    Customer.lastName +
                    " " +
                    Customer.firstName +
                    " ha cambiado sus datos de " +
                    datosEditados +
                    " " +
                    compararObjetos(
                      user.user.data.Customers,
                      temporalUser.user.data.Customers
                    ),
                });
              } catch (logError) {
                console.error("Error al solicitr cambio de datos:", logError);
              }
            }
          })
          .catch((error) => {
            setState({ ...state, guardando: false });
            if (error.response.status === 500) {
              setMessage({
                error: true,
                message: error.response.data.Message.Generic,
              });
            } else {
              setMessage({
                error: true,
                message: "Ha ocurrido une error al tratar de guardar los datos",
              });
            }
          })
          .finally(() => {
            toggle.onToggle();
          });
      }
    }
  };

  const visibilidadMiCobertura = () => {
    if (!state["visibleMiCobertura"]) {
      ReactGA.event({
        category: `Acciones-Perfil-${userPSHB ? "PSHB" : "HB"}`,
        action: "click",
        label: "cobertura",
      });
    }
    onVisibleTarjeta("visibleMiCobertura");
  };

  const visibilidadDatosContacto = () => {
    if (!state["visibleDatoContacto"]) {
      ReactGA.event({
        category: `Acciones-Perfil-${userPSHB ? "PSHB" : "HB"}`,
        action: "click",
        label: "contacto",
      });
    }
    onVisibleTarjeta("visibleDatoContacto");
  };

  return (
    <MessageContext.Provider value={message}>
      <Row
        className={"g-0 datosCobertura " + (userPSHB ? "userPSHB" : "userHB")}
      >
        <Col className="col-12 col-lg-6">
          <Message visible={toggle.toggle} onToggle={toggle.onToggle} />

          <div className="seccion-cobertura">
            {/* DATOS PERSONALES */}
            <TarjetaDatoCoberturaComponent
              titulo="Datos personales"
              activo={
                state.visibleDatoContacto || state.visibleMiCobertura
                  ? false
                  : true
              }
              puedeMinimizar={false}
            >
              <DatoPersonalComponent
                activo={
                  state.visibleDatoContacto || state.visibleMiCobertura
                    ? false
                    : true
                }
                editando={state.editandoDatoPersonal}
                onEditando={() => onEditando("editandoDatoPersonal")}
              />
            </TarjetaDatoCoberturaComponent>
          </div>
        </Col>
        <Col className="col-12 col-lg-6">
          <div className="seccion-cobertura">
            {/* DATO CONTACTO */}
            <TarjetaDatoCoberturaComponent
              titulo="Datos de contacto"
              activo={state.visibleDatoContacto}
              puedeMinimizar={true}
              onVisibleTarjeta={() => {
                visibilidadDatosContacto();
              }}
            >
              <DatoContactoComponent
                activo={state.visibleDatoContacto}
                editando={state.editandoDatoContacto}
                onEditando={() => onEditando("editandoDatoContacto")}
                guardando={state.guardando}
                confirmar={(temporalUser) =>
                  confirmar("editandoDatoContacto", temporalUser)
                }
                cancelar={(setTemporalUser) =>
                  cancelar("editandoDatoContacto", setTemporalUser)
                }
              />
            </TarjetaDatoCoberturaComponent>
          </div>
          <div className="seccion-cobertura">
            {/* MI COBERTURA */}
            <TarjetaDatoCoberturaComponent
              titulo="Mi cobertura"
              activo={state.visibleMiCobertura}
              puedeMinimizar={true}
              onVisibleTarjeta={() => visibilidadMiCobertura()}
            >
              <MiCoberturaComponent
                activo={state.visibleMiCobertura}
                key={keyCobertura}
                editando={state.editandoMiCobertura}
                guardando={state.guardando}
                onEditando={() => onEditando("editandoMiCobertura")}
                confirmar={(temporalUser) =>
                  confirmar("editandoMiCobertura", temporalUser)
                }
                cancelar={(setTemporalUser) =>
                  cancelar("editandoMiCobertura", setTemporalUser)
                }
              />
            </TarjetaDatoCoberturaComponent>
          </div>
        </Col>
      </Row>
    </MessageContext.Provider>
  );
};
