import { Col, Row } from "react-bootstrap";
import "./invoices.scss";
import ResultsComponent from "../../../components/hb/results";
import InvoicesComponent from "../../../components/pshb/invoices";
import UserSidebar from "../../../components/common/user-sidebar";
import React, { useContext, useState, useRef, useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";
import { fetchLogs } from "../../../utils/fetch";
import { UserGlobalContextMemorySpace } from "../../../contexts/user/userGlobalContext";

const Invoices = (props) => {
  const [routes, setRoutes] = useState({
    routes: [{ route: "/facturas", name: "Descargá tu factura" }],
  });
  const ref = useRef(null);
  const [height, setHeight] = useState(0);
  const location = useLocation();
  const { user } = useContext(UserGlobalContextMemorySpace);

  useLayoutEffect(() => {
    try {
      fetchLogs({
        idLogType: 1,
        idLog: 17,
        source: "pshb",
        email: user.user.data.Customers.email,
        dni: user.user.data.Customers.dni ?? "",
        credencial: user.user.data.Customers.credencial ?? "",
        description:
          "El usuario " +
          user.user.data.Customers.lastName +
          " " +
          user.user.data.Customers.firstName +
          " accedió al área de facturación",
      });
    } catch (logError) {
      console.error("Error al acceder al área de facturación:", logError);
    }
    setHeight(ref.current.clientHeight);
  }, []);

  return (
    <div className="d-flex help-page" ref={ref}>
      <UserSidebar routes={routes} height={height}></UserSidebar>
      <div className="bodypages"></div>
      <InvoicesComponent
        visible={location.state?.visible ? location.state?.visible : false}
        ref={ref}
      />
    </div>
  );
};

export default Invoices;
