import React, { useContext, useState } from "react";
import { UserGlobalContextMemorySpace } from "../../../contexts/user/userGlobalContext";
import ImagenFondo from "../../../assets/img/pshb/HeroImage.jpg";
import IcoWP from "../../../assets/img/hb/Icon-awesome-whatsapp.png";
import NumeroAtencion from "../../../assets/img/hb/numero-atencion.png";
import LogoHBOnline from "../../../assets/img/pshb/Logo HB.png";
import { GrupoMenuComponent } from "./grupo-menu";
import { MenuSecundario } from "./menu-secundario";
import "./stylee.scss";
import NovedadesIcono from "../../../assets/img/NovedadesIcono.png";
import { useNavigate } from "react-router-dom";
import WhatsappComponent from "../../../components/common/whatsapp";
import { fetchExternal } from "../../../utils/fetch";
import { useToggle } from "../../../utils/hooks/useToggle";
import { Information } from "../../../components/common/information/information";

const HomePSHB = (props) => {
  //  const handleSesion = () => {
  //      setUser(null)
  //  }

  //  const changeStack = () => {
  //      setUserPSHB(!userPSHB)
  //  }

  const { sitePSHB, setSitePSHB, userPSHB, setUserPSHB, user, setUser } =
    useContext(UserGlobalContextMemorySpace);

  const [novedad, setNovedad] = React.useState(false);
  const [visibleMenu, setVisibleMenu] = React.useState(false);
  const [slides, setSlides] = React.useState(null);
  const [loader, setLoader] = React.useState(true);
  const [info, setInfo] = React.useState(null);

  const navigate = useNavigate();
  const informationToggle = useToggle();

  React.useEffect(() => {
    const dniType = user.user.data.Customers.dniType;
    if (dniType === "1" || dniType === "3") {
      informationToggle.onToggle();
    }
    getData();
    getInfo();
  }, []);

  const getData = async () => {
    try {
      const { data } = await fetchExternal(
        `${process.env.REACT_APP_STRAPI_QA}/home-slides?eliminado=false&onOff=true&idEmpresa=2&_sort=orden:ASC`,
        "GET"
      );
      setSlides(data[0]);
      setLoader(false);
    } catch (error) {
      setLoader(false);
    }
  };

  const getInfo = async () => {
    try {
      const { data } = await fetchExternal(
        `${process.env.REACT_APP_STRAPI_QA}/pagina-principals?idEmpresa=2`,
        "GET"
      );
      setInfo(data[0]);
      // console.log("🚀 ~ file: index.js ~ line 60 ~ getInfo ~ data", data)
      setLoader(false);
    } catch (error) {
      setLoader(false);
    }
  };

  return (
    <>
      <Information
        visible={informationToggle.toggle}
        tipo={user.user.data.Customers.dniType}
        onToggle={informationToggle.onToggle}
        sitio="PSHB"
      />
      <WhatsappComponent />
      <MenuSecundario
        visible={visibleMenu}
        onHide={() => setVisibleMenu(false)}
      />

      <div className="contenedorBanner">
        {/* Img de Fondo */}
        <img
          src={slides?.media.url}
          className="imagenFondo"
          alt="Banner principal"
          onMouseEnter={() => setNovedad(false)}
        />

        {/* Plan de Salud */}
        <div className="d-none d-lg-block">
          {!visibleMenu && (
            <div className="planSalud">
              <img
                src={LogoHBOnline}
                className="img-fluid"
                alt="Logo plan salud"
                onMouseEnter={() => setNovedad(false)}
              />
              <span onClick={() => setVisibleMenu(true)}>HB ONLINE</span>
            </div>
          )}
        </div>

        {/* Bienvenida */}
        <div className="bienvenidaHB-movil bienvenidaHB-pc fs-1">
          <span className="titulo-bienvenido">
            Bienvenido <br />a tu Plan de Salud
          </span>
        </div>
        {/* Novedades */}
        {/* {info?.estadoNovedad &&
                    <>
                    <div className='contenedorNovedades-movil contenedorNovedades-pc novedad-mobile' onClick={() => setNovedad(previus=>!previus)}>
                        {!novedad && <div className='novedadesCabPS'><span className='fs-6'>Novedades<img src={NovedadesIcono} className='iconoNovedades'/></span></div>}
                        {novedad && <div className='novedadesDetPS'>
                            <span className='fs-6'>Novedades <img src={NovedadesIcono} className='iconoNovedades'/></span>
                            <p id="novedadestextPS" className='text-justify'>{info?.novedad}</p>
                        </div>}
                    </div>

                    <div className='contenedorNovedades-movil contenedorNovedades-pc novedad-desktop' onMouseEnter={() => setNovedad(true)} onMouseOut={() => setNovedad(false)}>
                        {!novedad && <div className='novedadesCabPS'><span className='fs-6'>Novedades<img src={NovedadesIcono} className='iconoNovedades'/></span></div>}
                        {novedad && <div className='novedadesDetPS'>
                            <span className='fs-6'>Novedades <img src={NovedadesIcono} className='iconoNovedades'/></span>
                            <p id="novedadestextPS" className='text-justify'>{info?.novedad}</p>
                        </div>}
                    </div>

                    </>
                    }    */}

        {info?.estadoNovedad && (
          <>
            <div
              className="contenedorNovedades-movil contenedorNovedades-pc novedad-mobile"
              onClick={() => setNovedad((previus) => !previus)}
            >
              {!novedad && (
                <div className="novedadesCabPS">
                  <span className="fs-6">Novedades</span>
                  <img src={NovedadesIcono} className="iconoNovedades" />
                </div>
              )}
              {novedad && (
                <div className="novedadesDetPS">
                  <span className="fs-6">
                    Novedades{" "}
                    <img src={NovedadesIcono} className="iconoNovedades" />
                  </span>
                  <p id="novedadestextPS" className="text-justify">
                    {info?.novedad}
                    {info?.enlaceNovedad && (
                      <a
                        style={{ marginLeft: "2px", fontWeight: "600" }}
                        id="novedadestextPS"
                        href={info?.enlaceNovedad}
                        target="_blank"
                      >
                        Click aquí
                      </a>
                    )}
                  </p>
                </div>
              )}
            </div>

            <div
              className="contenedorNovedades-movil contenedorNovedades-pc novedad-desktop"
              onMouseEnter={() => setNovedad(true)}
            >
              {!novedad && (
                <div className="novedadesCabPS">
                  <span className="fs-6">Novedades</span>
                  <img src={NovedadesIcono} className="iconoNovedades" />
                </div>
              )}
              {novedad && (
                <div className="novedadesDetPS">
                  <span className="fs-6">
                    Novedades{" "}
                    <img src={NovedadesIcono} className="iconoNovedades" />
                  </span>
                  <p id="novedadestextPS" className="text-justify">
                    {info?.novedad}
                    {info?.enlaceNovedad && (
                      <a
                        style={{ marginLeft: "2px", fontWeight: "600" }}
                        id="novedadestextPS"
                        href={info?.enlaceNovedad}
                        target="_blank"
                      >
                        Click aquí
                      </a>
                    )}
                  </p>
                </div>
              )}
            </div>
          </>
        )}

        {/* Atenciòn al público */}
        <div className="atencionps">
          <span className="text-center">
            <img src={NumeroAtencion} className="iconosAtencion" alt="..." />
          </span>
        </div>

        {/* Menú grupo */}
        <div className="contenedorGrupoMenu-movil contenedorGrupoMenu-lg contenedorGrupoMenu-xl contenedorGrupoMenu-xxl">
          <GrupoMenuComponent />
        </div>
      </div>
    </>
  );
};

export default HomePSHB;
